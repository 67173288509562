import React from "react";
import axios from "axios";
import { properties } from "../properties";
import * as Auth from "../AuthService";
import { ButtonGroup, Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import * as _ from 'lodash'
import './DataAnnotator.css'
import api from "../api";
import { Spin } from "antd";
import "../index.less";

class Results extends React.Component {

    render() {

        let result = this.props.annotated.map((v, i) =>
            <ListGroup.Item style={{ paddingTop: '0.15rem', paddingBottom: '0.15rem' }} key={i}>
                [ {v.attr} ] - {v.value}
                <img alt='delete' className='float-right' src='/images/close-black.png' onClick={() => this.props.onDelete(v)} />
                {v.match && v.match.status === 'full' && <img alt='full match' className='float-right' title={v.match.value} src='/images/check.png' />}
                {v.match && v.match.status === 'part' && <img alt='part match' className='float-right' title={v.match.value} src='/images/part.png' />}
                {v.match && v.match.status === 'none' && <img alt='none match' className='float-right' title={v.match.value} src='/images/none.png' />}
            </ListGroup.Item>);

        return (
            <div style={{ marginTop: 10, userSelect: 'none' }} onSelectCapture={() => false}>
                <Card border="secondary">
                    <Card.Header>You annotated:
                        <Button type='submit' variant="secondary" className='float-right primary-button' onClick={() => this.props.onSubmit()}>Submit Annotation</Button>
                        <Button style={{ marginRight: 10 }} type='reset' variant="secondary" className='float-right primary-button'
                            onClick={() => this.props.onReset()}>Reset annotation</Button>
                    </Card.Header>
                    <Card.Body>
                        <ListGroup variant="flush">
                            {result}
                        </ListGroup>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

class AttributeMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }

    boldText(value, v) {
        return value.substr(0, v.from) +
            '<span title="' + v.attr + '" class="selected" style=background-color:' + v.color + '>' + value.substr(v.from, v.length) +
            '<img style=background-color:' + v.color + ' class="delete-attr" alt=Delete src="/images/close-white.png"/></span>' +
            value.substr(v.from + v.length)
    }

    checkSelection(annotation, showed) {

        function isOverlapping(annotation1, annotation2) {
            return !((annotation1.from + annotation1.length < annotation2.from) || (annotation2.from + annotation2.length < annotation1.from));
        }

        for (let i = 0; i < showed.length; i++) {
            if (isOverlapping(annotation, showed[i])) {
                return false;
            }
        }
        return true;
    }

    createDescription() {

        if (this.props.annotated.length === 0) {
            return this.props.fullDescr;
        } else {
            let sorted = _.sortBy(this.props.annotated, [function (o) {
                return o.from + o.length;
            }]);
            let value = this.props.fullDescr;
            let showed = [];
            for (let i = sorted.length; i > 0; i--) {
                if (this.checkSelection(sorted[i - 1], showed)) {
                    value = this.boldText(value, sorted[i - 1]);
                    showed.push(sorted[i - 1]);
                }
            }
            return value;
        }
    }

    clickHandler(e) {
        let el = e.target;
        if (el && el.tagName === "IMG") {
            el = el.parentNode;

            let value = _.filter(this.props.annotated, { 'value': el.textContent })
            let token = _.filter(this.props.annotated, { 'token': el.textContent })
            if (value.length === 0) {
                this.props.onDelete(token[0])
            } else {
                this.props.onDelete(value[0])
            }
        }
    }

    render() {

        return (
            <div style={{ marginBottom: 10, userSelect: 'none' }} onSelectCapture={() => false}>
                <Card border="secondary">
                    <Card.Header>Attributes Map:</Card.Header>
                    <Card.Body>
                        <div onClick={(e) => this.clickHandler(e)}
                            dangerouslySetInnerHTML={{ __html: this.createDescription() }} />
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

class DataAnnotator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading_hints: false,
            loading_verify: false,
            attributes: [],
            annotated: [],
            markups: [],
            serviceAttributes: []
        }
    }

    getColor(attr) {
        if (_.filter(this.state.serviceAttributes, { 'attributeName': attr }).length > 0) {
            const serviceIndex = this.state.serviceAttributes.indexOf(_.filter(this.state.serviceAttributes, { 'attributeName': attr })[0]);
            const nameColor = Object.keys(properties.shades)[serviceIndex];
            return properties.shades[nameColor];
        }
        const attrIndex = this.state.attributes.indexOf(_.filter(this.state.attributes, { 'attributeName': attr })[0]) + this.state.serviceAttributes.length;
        const nameColor = Object.keys(properties.shades)[attrIndex];
        return properties.shades[nameColor];
    }

    getMarkups() {
        api.get(`/markup?item=${this.props.currentItem.item}`, Auth.createConfig())
            .then(json => {
                let markups = json.data[0].manualMarkups;
                for (let i = 0; i < markups.length; i++) {
                    markups[i].color = this.getColor(markups[i].attr);
                    markups[i].value = this.props.currentItem.description.substr(markups[i].from, markups[i].length)
                }
                this.setState({ markups: markups.length > 0 ? markups : [], annotated: markups.length > 0 ? markups : [] })
            })
            .catch(error => console.log(error))
    }

    getAttrs() {
        api.get(`/attributes?class=${this.props.currentItem.class_name}`, Auth.createConfig())
            .then(json => this.setState({ attributes: json.data.items }))
            .catch(error => console.log(error));
        api.get(`/service-attributes`, Auth.createConfig())
            .then(json => this.setState({ serviceAttributes: json.data.attrs }))
            .catch(error => console.log(error));
    }

    componentDidMount() {
        this.getAttrs();
        this.getMarkups();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentItem !== this.props.currentItem) {
            if (prevProps.currentItem.class_name !== this.props.currentItem.class_name) {
                this.getAttrs();
            }
            this.getMarkups();
            // this.setState({
            //     annotated: []
            // })
        }
    }

    checkSelection(annotation) {

        function isOverlapping(annotation1, annotation2) {
            return !((annotation1.from + annotation1.length < annotation2.from) || (annotation2.from + annotation2.length < annotation1.from));
        }

        const markups = this.state.markups;
        for (let i = 0; i < markups.length; i++) {
            if (isOverlapping(annotation, markups[i])) {
                return false;
            }
        }
        return true;
    }

    handleSelection(button, color) {
        const s = window.getSelection().toString();
        if (s) {
            const from = window.getSelection().anchorOffset;
            let annotated = [...this.state.annotated];
            let markups = [...this.state.markups];
            
            const values = s.split(",").filter(value => value.trim() !== "");

            const initialMarkupsLength = markups.length;
            const initialAnnotatedLength = annotated.length;
    
            values.forEach((value, index) => {
                const trimmedValue = value.trim();

                const leadingSpaces = value.length - value.trimStart().length;

                const annotation = {
                    attr: button,
                    value: trimmedValue,
                    length: trimmedValue.length,
                    from: from + (index > 0 ? values.slice(0, index).join(",").length + 1 : 0) + leadingSpaces,
                    color: color
                };
    
                if (this.checkSelection(annotation) || markups.length === 0) {
                    markups.push(annotation);
                }
                annotated.push(annotation);
            });
    
            if (annotated.length !== initialAnnotatedLength) {
                this.setState({ markups: markups, annotated: annotated });
            }
        }
    }

    onSubmit() {
        let annotated = [];
        for (let i = 0; i < this.state.annotated.length; i++) {
            annotated.push({
                attr: this.state.annotated[i].attr,
                length: this.state.annotated[i].length,
                from: this.state.annotated[i].from
            })
        }
        let result = {
            id: this.props.currentItem.item,
            annotated: annotated
        };
        api.post(`/save/markup`, result, Auth.createConfig())
            .then(json => this.props.onSubmit())
            .catch(error => console.log(error))

    }

    getHints(re_get = false) {
        let result = {
            description: this.props.currentItem.description,
            re_get: re_get
        };
        if (re_get) {
            this.setState({ annotated: [], markups: [] })
        }
        this.setState({ loading_hints: true })
        api.post(`/hints/llm?item_id=${this.props.currentItem._id}`, result, Auth.createConfig())
            .then(json => {
                let markups = this.state.markups;
                let annotated = this.state.annotated;
                for (let i = 0; i < json.data.items.length; i++) {
                    let annotation = json.data.items[i];
                    annotation.color = this.getColor(json.data.items[i].attr);
                    if (annotated.findIndex(a => a.attr === annotation.attr && a.from === annotation.from && a.length === annotation.length) !== -1) {
                        continue;
                    }
                    if (this.checkSelection(annotation) || markups.length === 0) {
                        markups.push(annotation);
                    }
                    annotated.push(annotation);
                }
                this.setState({ annotated: annotated, markups: markups })
            })
            .catch(error => {
                console.log(error);
                alert("Sorry, couldn't process this item.");
            })
            .finally(() => this.setState({ loading_hints: false }))
    }

    verify() {
        let result = {
            description: this.props.currentItem.description,
            annotations: this.state.annotated
        };
        this.setState({ loading_verify: true })
        api.post(`/verify/llm?item_id=${this.props.currentItem._id}`, result, Auth.createConfig())
            .then(json => {
                let verified = json.data.items;
                this.setState({ annotated: verified })
            })
            .catch(error => {
                console.log(error);
                alert("Sorry, couldn't process this item.");
            })
            .finally(() => this.setState({ loading_verify: false }))
    }

    onDelete(i) {
        let old_annotated = this.state.annotated;
        let old_markups = this.state.markups;

        let match_markup = old_markups.find(x => (x.attr === i.attr) && (x.value === i.value))
        if (match_markup !== undefined) {
            old_markups.splice(old_markups.indexOf(match_markup), 1);
        }
        let match_annotated = old_annotated.find(x => (x.attr === i.attr) && (x.value === i.value))
        if (match_annotated !== undefined) {
            old_annotated.splice(old_annotated.indexOf(match_annotated), 1);
        }
        this.setState({ annotated: old_annotated, markups: old_markups })
    }

    groupButtons(array, offset = 0, name = 'attributes') {
        const shadeArr = Object.keys(properties.shades)
        return array.map((v, i) => {
            let class_name = 'single-btn primary-button';
            let filterObj = { 'attr': v.attributeName };
            if (_.filter(this.state.annotated, filterObj).length > 0) {
                class_name = 'selected-btn primary-button';
            }
            const nameColor = shadeArr[i + offset]
            return <Button className={class_name} style={{ backgroundColor: properties.shades[nameColor], fontSize: 13 }}
                key={i + offset}
                onClick={() => this.handleSelection(v.attributeName, properties.shades[nameColor])}>
                {v.customName !== v.attributeName && <div>
                    <i style={{ textDecoration: 'underline', fontSize: 10, float: 'left', marginBottom: 0, marginRight: 0, marginLeft: 3 }}>
                        {v.customName}</i>
                </div>}
                {v.attributeName}
            </Button>
        })
    }

    render() {
        if (this.props.currentItem.description) {

            if (this.state.attributes) {
                let full = this.state.attributes;

                let service = this.groupButtons(this.state.serviceAttributes, 0, 'serviceAttributes');

                let attributeCount = full.length;
                let group1 = this.groupButtons(full.slice(0, _.floor((attributeCount + 1) / 2)), this.state.serviceAttributes.length);
                let group2 = this.groupButtons(full.slice(_.ceil((attributeCount) / 2)), this.state.serviceAttributes.length + 14);

                return (
                    <div className='row'>
                        <ButtonGroup style={{ justifyContent: 'start', height: '50px' }} vertical
                            className='col-md-2'>
                            {group1}
                        </ButtonGroup>
                        {
                            group2.length > 0 &&
                            <ButtonGroup style={{ justifyContent: 'start', height: '50px' }} vertical className='col-md-2'>
                                {group2}
                            </ButtonGroup>
                        }
                        <div style={{ marginTop: 10 }} className={group2.length > 0 ? 'col-md-8' : 'col-md-10'}>
                            <AttributeMap annotated={this.state.markups}
                                fullDescr={this.props.currentItem.description}
                                onDelete={(i) => this.onDelete(i)} />
                            <Card border="secondary">
                                <Card.Header style={{ userSelect: 'none' }}
                                    onSelectCapture={() => false}>
                                    Item ID: {this.props.currentItem.item}
                                    {this.state.annotated.length === 0 && (
                                        <Button className='float-right primary-button'
                                            onClick={() => this.getHints()}
                                            disabled={this.state.loading}>
                                            {this.state.loading_hints ? 'Getting Hints... ' : 'Get Hints'}
                                            {this.state.loading_hints && <Spin />}
                                        </Button>
                                    )}
                                    {/*this.state.annotated.length > 0 && (
                                        <Button className='float-right'
                                                onClick={() => this.getHints(true)}
                                                disabled={this.state.loading}
                                        >
                                            {this.state.loading_hints ? 'Getting Hints... ' : 'Re-Get Hints'}
                                            {this.state.loading_hints && <Spin/>}
                                        </Button>
                                    )*/}
                                    {this.state.annotated.length > 0 && (
                                        <Button className='float-right primary-button'
                                            onClick={() => this.verify()}
                                            disabled={this.state.loading}
                                        >
                                            {this.state.loading_verify ? 'Verifying...' : 'Verify annotations'}
                                            {this.state.loading_verify && <Spin />}
                                        </Button>
                                    )}
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {this.props.currentItem.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Results annotated={this.state.annotated} onDelete={(i) => this.onDelete(i)}
                                onReset={() => this.setState({ annotated: [], markups: [] })}
                                onSubmit={() => this.onSubmit()}
                            />
                        </div>
                        {/* <ButtonGroup style={{justifyContent: 'start', height: '50px'}} vertical className='col-md-2'>
                            {service}
                        </ButtonGroup> */}
                    </div>
                );
            } else {
                return (
                    <div>
                        [{this.props.currentItem.item}] {this.props.currentItem.description}
                    </div>
                );
            }
        } else {
            return <h5 className='m-auto'>You need to select item first!</h5>
        }
    }

}

export default DataAnnotator;
