import { useState, useEffect } from "react";
import _ from "lodash";
import { properties } from "../properties";

export default function Description({item, shortDescription, displayedValuesWithPositions}) {

    const [description, setDescription] = useState('');

    useEffect(() => {
        setDescription(item.description);
    }, [item]);

    useEffect(() => {
        setDescription(createDescription());
    }, [displayedValuesWithPositions]);


    const boldText = (value, v) => {
        return value.substr(0, v.from) +
            '<span title="' + v.attr + '" class="selected" style=background-color:'+`${properties.shades.mintGreen}` + '>' + value.substr(v.from, v.length) + '</span>' +
            value.substr(v.from + v.length)
    }

    const checkSelection = (annotation, showed) => {

        function isOverlapping(annotation1, annotation2) {
            return !((annotation1.from + annotation1.length < annotation2.from) || (annotation2.from + annotation2.length < annotation1.from));
        }

        for (let i = 0; i < showed.length; i++) {
            if (isOverlapping(annotation, showed[i])){
                return false;
            }
        }
        return true;
    }

    const createDescription = () => {
        if (displayedValuesWithPositions.length === 0) {
            return item.description;
        } else {
            let sorted = _.sortBy(displayedValuesWithPositions, [function (o) {
                return o.from + o.length;
            }]);
            let value = item.description;
            let showed = [];
            for (let i = sorted.length; i > 0; i--) {
                if(checkSelection(sorted[i - 1], showed)) {
                    value = boldText(value, sorted[i - 1]);
                    showed.push(sorted[i - 1])
                }
            }
            return value;
        }
    }


    return(     
        <>
            <div dangerouslySetInnerHTML={{__html: description}}/>
            {shortDescription ? 
                <div>
                    <strong>Short description:</strong> {shortDescription} <strong>(length: {shortDescription.length})</strong>
                </div> :
                null
            }
            
        </>      
        
    );
}