import React, { useState, useEffect, useRef } from "react";
import { Card, Button, Space, Tag } from "antd";
import * as Auth from "../AuthService";
import api from "../NewApi";
import Metrics from "./Metrics";
import GeneralAttributes from "./GeneralAttributes";
import Description from "./Description";
import QAHistory from "./QAHistory";
import QATable from "./QATable";
import "../index.less";
import "./QAManager.css";


export default function QAManager({ item, getNext, tabKey }) {

    const prevTabKey = useRef("");

    // Information about the class
    const [attributes, setAttributes] = useState([]);
    const [classInfo, setClassInfo] = useState({});

    const [shortDescription, setShortDescription] = useState("");

    // Tags of the item
    const [tags, setTags] = useState([]);

    // Final attributes represent submitted (verified) attributes
    const [verifiedValues, setVerifiedValues] = useState({});
    const [verifiedPositions, setVerifiedPositions] = useState([]);

    // Annotated represent attributes that were annotated by the user in AnnotationManager
    const [annotatedValues, setAnnotatedValues] = useState({});
    const [annotatedPositions, setAnnotatedPositions] = useState([]);

    // Regex extraction represent attributes that were extracted by the regex
    const [regexExtractionValues, setRegexExtractionValues] = useState({});
    const [regexExtractionPositions, setRegexExtractionPositions] = useState([]);

    // LLMExtraction represent attributes that were extracted by the LLM
    const [llmExtractionValues, setLlmExtractionValues] = useState({});
    const [llmExtractionPositions, setLlmExtractionPositions] = useState([]);

    // Standard values represent attributes that were standardized (4MM -> 4mm). Applies to annotation, regex and llm values 
    const [standardValues, setStandardValues] = useState({});

    // Final values is attributes that in the last column of the table (Final Values) after standardization + Final Attributes
    const [finalValues, setFinalValues] = useState({});
    const [finalValuesPositions, setFinalValuesPositions] = useState([]);

    const tempFinalValues = useRef({});

    // Displayed values are the values that are displayed in the description card
    const [displayedValuesWithPositions, setDisplayedValuesWithPositions] = useState([]);

    // Flag whether all attributes were verified
    const [allAttributesVerified, setAllAttributesVerified] = useState(false);

    useEffect(() => {
        if ("verified" in tags) {
            setAllAttributesVerified(true);
        }
    }, [tags]);

    const getSHortDescription = async() => {
        try {
            const re = await api.get(`/material/short-description?material=${item.item}`, Auth.createConfig())
            const data = re.data;

            if (data.success) {
                setShortDescription(data.result.short_description)
            }
        } catch (error) {
            console.error("Error while calling getSHortDescription:", error.message);
        }
    };

    const standardizeFinalValues = async (_finalValues) => {
        try {
            const re = await api.post("/standard", _finalValues, Auth.createConfig())

            let standardizedValues = {};
            let standardizedFinalValues = {};

            for (let attr in re.data.items) {
                let field = re.data.items[attr].field;
                let value = re.data.items[attr].std;

                standardizedValues[attr] = value;
                standardizedFinalValues[attr] = { value, field };
            }

            setStandardValues(standardizedValues);
            setFinalValues(standardizedFinalValues);
        } catch (error) {
            console.error("Error while calling standardizeFinalValues:", error.message);
        }
    }

    const getFinalValues = (attributes, verifiedValues, annotatedValues, regexExtractionValues, llmExtractionValues) => {
        let _finalValues = {};
        for (let attr of attributes) {
            let attributeName = attr.attributeName;
            let verifiedValue = verifiedValues[attributeName];
            let annotatedValue = annotatedValues[attributeName];
            let extractedValue = regexExtractionValues[attributeName];
            let llmExtractedValue = llmExtractionValues[attributeName];

            let value = "";
            let field = "";

            if (verifiedValue !== undefined) {
                value = verifiedValue;
                field = "finalAttribute";
            } else if (annotatedValue !== undefined) {
                value = annotatedValue;
                field = "annotation";
            } else if (extractedValue !== undefined) {
                value = extractedValue;
                field = "regexExtraction";
            } else if (llmExtractedValue !== undefined) {
                value = llmExtractedValue;
                field = "llmExtraction";
            }

            _finalValues[attributeName] = { value, field };
        }
        tempFinalValues.current = _finalValues;
        standardizeFinalValues(_finalValues);
    };

    const displayAllAttributes = () => {
        setDisplayedValuesWithPositions(finalValuesPositions);
    }

    const getClassInfo = async () => {
        try {
            const re = await api.get(`/classes/class?class=${item.class_name}`, Auth.createConfig(), true)

            const { attributes, ...rest } = re.data;
            setAttributes(attributes);
            setClassInfo(rest);
            return attributes;
        } catch (error) {
            console.error("Error while calling getClassInfo:", error.message);
        }
    }

    const getTags = async () => {
        try {
            const re = await api.get(`/item/tags?item=${item.item}`, Auth.createConfig(), false);
            setTags(re.data.tags);
        } catch (error) {
            console.error("Error fetching tags:", error);
        }
    }

    const getAnnotated = async () => {
        try {
            const re = await api.get(`/markup?item=${item.item}`, Auth.createConfig(), false);

            let annotatedPositions = re.data[0].manualMarkups;
            let annotatedValues = {};
            for (let i = 0; i < annotatedPositions.length; i++) {
                let attr = annotatedPositions[i].attr;
                if (attr in annotatedValues) {
                    annotatedValues[attr] = `${annotatedValues[attr]}, ${item.description.substr(annotatedPositions[i].from, annotatedPositions[i].length)}`;
                } else {
                    annotatedValues[attr] = item.description.substr(annotatedPositions[i].from, annotatedPositions[i].length);
                }
            }
            setAnnotatedValues(annotatedValues);
            setAnnotatedPositions(annotatedPositions);
            return annotatedValues;
        } catch (error) {
            console.error("Error while calling getAnnotated:", error);
        }
    }

    const getRegexExtraction = async () => {
        try {
            const re = await api.get(`/item/extraction?item=${item.item}`, Auth.createConfig(), false);

            let regexExtractionValues = {};
            for (let i = 0; i < re.data.items.length; i++) {
                let attr = re.data.items[i].attr;
                if (attr in regexExtractionValues) {
                    regexExtractionValues[attr] = `${regexExtractionValues[attr]}, ${re.data.items[i].value}`;
                } else {
                    regexExtractionValues[attr] = re.data.items[i].value;
                }
            }
            setRegexExtractionValues(regexExtractionValues);
            setRegexExtractionPositions(re.data.items);
            return regexExtractionValues;
        } catch (error) {
            console.error("Error while calling getRegexExtraction:", error);
        }
    }

    const getLLMExtraction = async () => {
        try {
            const re = await api.get(`/item/llm-extraction?item_id=${item._id}`, Auth.createConfig(), true);

            let llmExtraction = {};
            for (let i = 0; i < re.data.items.length; i++) {
                let attr = re.data.items[i].attr;
                if (!(attr in llmExtraction)) {
                    llmExtraction[attr] = [re.data.items[i].value];
                } else if (!llmExtraction[attr].includes(re.data.items[i].value)) {
                    llmExtraction[attr].push(re.data.items[i].value);
                }

            }
            let llmExtractionValues = Object.fromEntries(
                Object.entries(llmExtraction).map(([key, value]) => [key, value.join(", ")])
            );
            setLlmExtractionValues(llmExtractionValues);
            setLlmExtractionPositions(re.data.items);
            return llmExtractionValues;
        } catch (error) {
            console.error("Error while calling getLLMExtraction:", error);
        }
    }

    const getVerifiedPositions = async (verifiedValues) => {
        const data = {
            description: item.description,
            attributes: verifiedValues
        };

        const re = await api.post("/matching-attribute-tokens", data, Auth.createConfig());
        return re.data.items;
    }

    const getVerified = async () => {
        try {
            const re = await api.get(`/final/all-attributes?item=${item.item}`, Auth.createConfig(), false)

            const verifiedValues = re.data.final_attributes;
            setVerifiedValues(verifiedValues);

            if (Object.keys(verifiedValues).length) {
                const verifiedPositions = await getVerifiedPositions(verifiedValues);
                setVerifiedPositions(verifiedPositions);
                return verifiedValues;
            }

            setVerifiedPositions([]);
            return [];
        } catch (error) {
            console.error("Error while calling getVerified:", error.message);
        }
    }

    useEffect(() => {
        const fetchAnnotatedData = async () => {
            if (tabKey == "qa" && prevTabKey.current == "annotate") {
                const newAnnotated = await getAnnotated();

                if (!newAnnotated) {
                    alert("Something went wrong. Please, contact with us about this issue, thank you!");
                    return;
                }

                getFinalValues(attributes, verifiedValues, newAnnotated, regexExtractionValues, llmExtractionValues);
                getTags();
            }
            prevTabKey.current = tabKey;
        }

        fetchAnnotatedData();
    }, [tabKey])

    useEffect(() => {
        const fetchAllData = async () => {
            if (!item) return;

            getTags();

            const [attributes, annotated, regexExtraction, LLMExtraction, verified] = await Promise.all([
                getClassInfo(),
                getAnnotated(),
                getRegexExtraction(),
                getLLMExtraction(),
                getVerified(),
            ]);

            if (!attributes || !annotated || !regexExtraction || !LLMExtraction || !verified) {
                alert("Something went wrong. Please, contact with us about this issue, thank you!");
                return;
            }

            getFinalValues(attributes, verified, annotated, regexExtraction, LLMExtraction);
        };

        fetchAllData();

        return () => {
            setAttributes([]);
            setClassInfo({});
            setShortDescription("");
            setTags([]);
            setVerifiedValues({});
            setVerifiedPositions([]);
            setAnnotatedValues({});
            setAnnotatedPositions([]);
            setRegexExtractionValues({});
            setRegexExtractionPositions([]);
            setLlmExtractionValues({});
            setLlmExtractionPositions([]);
            setStandardValues({});
            setFinalValues({});
            setFinalValuesPositions([]);
            tempFinalValues.current = {};
            setDisplayedValuesWithPositions([]);
            setAllAttributesVerified(false);
        }
    }, [item])

    const getFinalValuesPositions = (_finalValues) => {
        let positions = [];
        for (const attr in finalValues) {
            if (_finalValues[attr].field === "finalAttribute") {
                positions.push(...verifiedPositions.filter(item => item.attr === attr));
            } else if (_finalValues[attr].field === "annotation") {
                positions.push(...annotatedPositions.filter(item => item.attr === attr));
            } else if (_finalValues[attr].field === "regexExtraction") {
                positions.push(...regexExtractionPositions.filter(item => item.attr === attr));
            } else if (_finalValues[attr].field === "llmExtraction") {
                positions.push(...llmExtractionPositions.filter(item => item.attr === attr));
            }
        }

        if (positions != finalValuesPositions) {
            setFinalValuesPositions(positions);
        }
        return positions;
    }

    useEffect(() => {
        if (Object.keys(finalValues).length) {
            getFinalValuesPositions(tempFinalValues.current);
        }
    }, [finalValues])

    const handleSelectCell = (attr, value, field) => {
        value = value !== "-" ? value : "";
        if (value && !verifiedValues[attr]) {
            showAttribute(attr);
        } else {
            setDisplayedValuesWithPositions([]);
        }
    }

    const showAttribute = (attr) => {
        setDisplayedValuesWithPositions(finalValuesPositions.filter(item => item.attr === attr));
    }

    const saveFinalAttribute = async (value, attr) => {
        let data = {
            item: item.item,
            finalAttributes: {
                [attr]: value
            }
        };

        try {
            await api.post("/save/final/attributes", data, Auth.createConfig());

            const newFinalAttributes = await getVerified();
            getFinalValues(attributes, newFinalAttributes, annotatedValues, regexExtractionValues, llmExtractionValues);
            getTags();
        } catch (error) {
            console.error("Error while save final value:", error);
        } finally {
            setDisplayedValuesWithPositions([]);
        }
    }

    const refreshFinalValue = async (value, attr, field) => {
        try {
            await api.delete(`/delete/final/attributes?item=${item.item}&attributes=${attr}`, Auth.createConfig());

            const newFinalAttributes = await getVerified();
            getFinalValues(attributes, newFinalAttributes, annotatedValues, regexExtractionValues, llmExtractionValues);
            getTags();
        } catch (error) {
            console.error("Error while refresh final value:", error);
        } finally {
            setDisplayedValuesWithPositions([]);
        }
    }

    const handleSubmiting = async (isSubmit) => {
        const newVerifiedValues = await getVerified();
        getFinalValues(attributes, newVerifiedValues, annotatedValues, regexExtractionValues, llmExtractionValues);
        setAllAttributesVerified(isSubmit);
        getTags();
    }

    const submitAll = async () => {
        const data = {
            item: item.item,
            finalAttributes: Object.fromEntries(
                Object.entries(finalValues).map(([key, value]) => [key, value.value])
            )
        };

        try {
            await api.post("/save/final/attributes", data, Auth.createConfig());
            handleSubmiting(true);
        } catch (error) {
            console.error("Error while submit data:", error);
        } finally {
            setDisplayedValuesWithPositions([]);
        }
    }

    const unsubmitAll = async () => {
        const attributes_array = attributes.map(attr => attr.attributeName).join(",");
        try {
            await api.delete(`/delete/final/attributes?item=${item.item}&attributes=${attributes_array}`, Auth.createConfig());
            handleSubmiting(false);
        } catch (error) {
            console.error("Error while unsubmit data:", error);
        } finally {
            setDisplayedValuesWithPositions([]);
        }
    }

    return (
        <div>
            <ItemInfoCards item={item}
                tags={tags}
                displayedValuesWithPositions={displayedValuesWithPositions}
                shortDescription={shortDescription}
                getSHortDescription={getSHortDescription}
                getNext={() => { getNext() }}
                displayAllAttributes={() => displayAllAttributes()}
                classInfo={classInfo}
                finalValuesPositions={finalValuesPositions} />
            <QATable
                item={item}
                attributes={attributes}
                handleSelectCell={(attr, value, field) => handleSelectCell(attr, value, field)}
                saveFinalAttribute={(value, attr) => saveFinalAttribute(value, attr)}
                refreshFinalValue={(value, attr, field) => refreshFinalValue(value, attr, field)}
                showAttribute={(attr) => showAttribute(attr)}
                standardValues={standardValues}
                annotatedValues={annotatedValues}
                regexExtractionValues={regexExtractionValues}
                llmExtractionValues={llmExtractionValues}
                finalValues={finalValues}
            />
            <Space className="float-right">
                <QAHistory item={item} />
                <Button type="primary" className="float-right btn-primary" onClick={() => unsubmitAll()}>Unsubmit All</Button>
                {allAttributesVerified ?
                    <Button type="primary" className="float-right btn-primary" style={{ opacity: "0.5" }} onClick={() => { }}>Submit All</Button> :
                    <Button type="primary" className="float-right btn-primary" onClick={() => submitAll()}>Submit All</Button>
                }
            </Space>
        </div>
    );
}


function ItemInfoCards({ item, tags, displayedValuesWithPositions, shortDescription, getSHortDescription, getNext, displayAllAttributes, classInfo, finalValuesPositions }) {

    // State of displayed tab
    const [activeTab, setActiveTab] = useState('description');
    // Flag whether metrics tab content is visible (needed for correct calculation of metrics)
    const [visibleMetrics, setVisibleMetrics] = useState(false);


    const tabList = [
        { key: 'description', tab: 'Description' },
        { key: 'general-attributes', tab: 'General Attributes' },
        { key: 'metrics', tab: 'Metrics' },
    ];

    const contentList = {
        "description": <Description item={item} shortDescription={shortDescription}
            displayedValuesWithPositions={displayedValuesWithPositions} />,
        "general-attributes": <GeneralAttributes item={item} classInfo={classInfo} />,
        "metrics": <Metrics item={item} finalValuesPositions={finalValuesPositions} visibleMetrics={visibleMetrics} />,
    };

    const handleTabChange = (key) => {
        if (key === "metrics") {
            setVisibleMetrics(true);
        }
        setActiveTab(key);
    }

    return (
        <Card title={
            <Space>
                Item ID: {item.item} {tags && tags.map(tag => {
                    let color = null;
                    switch (tag) {
                        case 'annotated':
                            color = 'blue';
                            break;
                        case 'verified':
                            color = 'green';
                            break;
                        case 'partially verified':
                            color = 'orange';
                            break;
                        default:
                            color = 'grey';
                            break;
                    }
                    return (
                        <Tag color={color} key={tag} style={{ marginRight: 0 }}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </Space>
        } extra={
            activeTab === 'description' ? <Space>
                {item["class_name"] == "GASKET, SPIRAL WOUND" ? 
                    <Button type="primary" className="btn-primary" onClick={getSHortDescription}>Get Short Description</Button> :
                    null
                }
                <Button type="primary" className="btn-primary" onClick={() => { getNext(); setVisibleMetrics(false) }}>Get Next</Button>
                <Button type="primary" className="btn-primary" onClick={() => displayAllAttributes()}>Visualize</Button>
            </Space> : null
        }
            className="primary-card qa-card"
            tabList={tabList}
            activeTabKey={activeTab}
            onTabChange={key => {
                handleTabChange(key);
            }}
        >
            {contentList[activeTab]}
        </Card>
    )
}